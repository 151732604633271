import React, { createRef, useRef } from "react";
import Typography from "@mui/material/Typography";
import CodeEditor from "../components/editor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import New_Paste_Dialog from "../components/new_paste_dialog";
import axios from "axios";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { useNavigate, useParams } from "react-router-dom";
import Decrypt_Paste_Dialog from "../components/decrypt_paste_dialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export function withRouter(Children) {
  return (props) => {
    let navigate = useNavigate();
    const match = { params: useParams() };
    return <Children {...props} match={match} navigate={navigate} />;
  };
}

class Paste extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props, state);
    this.state = {
      paste: {
        uuid: props.paste_uuid,
        name: "",
        author: "",
        language: "",
        content: "",
        encrypted: "",
      },
      code_editor_ref: createRef(),
      decrypt_paste_dialog_ref: createRef(),
    };
  }

  async get_paste(uuid) {
    try {
      var response = await axios.get(
        `${process.env.REACT_APP_API_FQDN}/pastes/${uuid}`
      );
    } catch (error) {
      throw new Error(error.response.data.message || "Failed to get paste.");
    }
    let data = response.data;
    if (!data.name || data.name == "") {
      data.name = "Untitled paste.";
    }
    if (!data.author || data.author == "") {
      data.author = "Anonymous";
    }
    if (!data.language || data.language == "") {
      data.language = "plaintext";
    }
    if (!data.content || data.content == "") {
      data.content = "No content.";
    }
    this.setState({
      paste: {
        uuid: uuid,
        name: data.name,
        author: data.author,
        language: data.language,
        content: data.content,
        encrypted: data.encrypted,
      },
    });
    // If the paste is encrypted, show the decrypt dialog.
    if (data.encrypted) {
      this.state.decrypt_paste_dialog_ref.current.setState({
        dialog_open: true,
      });
      return;
    }
    this.paste_ready(); // Otherwise, just show the paste.
    return;
  }

  decrypt_paste_content(encrypted_content: string, password: string) {
    let decrypted_content = AES.decrypt(encrypted_content, password).toString(
      Utf8
    );
    if (decrypted_content == "") {
      throw new Error("Password is incorrect.");
    }
    return decrypted_content;
  }

  // Once we have all the data for the paste ready (and decrypted if needed).
  paste_ready() {
    // Set all properties for the editor.
    this.state.code_editor_ref.current.setState({
      value: this.state.paste.content,
      language: this.state.paste.language,
    });
  }

  redirect_to_home() {
    this.props.navigate("/", { replace: true });
  }

  // When the user entered a password to decrypt the paste.
  on_decrypt() {
    try {
      var paste_content = this.decrypt_paste_content(
        this.state.paste.content,
        this.state.decrypt_paste_dialog_ref.current.state.paste_password
      ); // Try to decrypt the paste content with the given password.
    } catch (error) {
      // If there's an error decrypting the paste content, show the error on the password text field in the dialog.
      this.state.decrypt_paste_dialog_ref.current.setState({
        password_props: {
          error: true,
          helperText: error.message,
        },
      });
      return;
    }
    // If the paste content was successfully decrypted, set the paste content in the editor and close the dialog.
    let paste = this.state.paste;
    paste.content = paste_content;
    this.setState({ paste: paste });
    this.state.decrypt_paste_dialog_ref.current.setState({
      dialog_open: false,
    });
    this.paste_ready();
  }

  editor_mounted(editor, monaco) {
    // Editor mounted
  }

  copy_paste_to_clipboard() {
    navigator.clipboard.writeText(this.state.paste.content);
  }

  async componentDidMount() {
    let uuid = this.props.match.params.uuid;
    this.get_paste = this.get_paste.bind(this);
    try {
      await this.get_paste(uuid);
    } catch (error) {
      if (error.message == "Paste not found or expired.") {
        this.props.navigate("/404_paste", { replace: true });
      }
    }
  }

  render() {
    this.on_decrypt = this.on_decrypt.bind(this);
    this.editor_mounted = this.editor_mounted.bind(this);
    this.redirect_to_home = this.redirect_to_home.bind(this);
    this.copy_paste_to_clipboard = this.copy_paste_to_clipboard.bind(this);
    return (
      <Box sx={{ display: "flex" }} style={{ height: "89vh", width: "95vw" }}>
        <Decrypt_Paste_Dialog
          ref={this.state.decrypt_paste_dialog_ref}
          on_cancel={this.redirect_to_home}
          on_decrypt={this.on_decrypt}
        />
        <Grid container spacing={1}>
          <Box
            sx={{
              mx: "auto",
            }}
            style={{ width: "95vw" }}
          >
            <Grid container spacing={1}>
              <Grid item md={9}>
                <Typography variant="h6">{this.state.paste.name}</Typography>
                <Typography variant="subtitle1">
                  by {this.state.paste.author}
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Button
                  onClick={this.copy_paste_to_clipboard}
                  variant="outlined"
                  startIcon={<ContentCopyIcon />}
                  sx={{ mx: 5 }}
                  style={{ float: "right" }}
                >
                  Copy
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12}>
            <CodeEditor
              editor_mounted={this.editor_mounted}
              ref={this.state.code_editor_ref}
              read_only={true}
              content=" "
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default withRouter(Paste);
