import React, { createRef, useRef } from "react";
import Typography from "@mui/material/Typography";
import CodeEditor from "../components/editor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import New_Paste_Dialog from "../components/new_paste_dialog";
import Created_Paste_Dialog from "../components/created_paste_dialog";
import axios from "axios";
import AES from "crypto-js/aes";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

interface Language {
  name: string;
  label: string;
  id: string;
  extensions: string[];
}

interface Paste {
  name: string;
  author: string;
  language: string;
  content: string;
  encrypted: string;
  password?: string;
  description?: string;
  expiration: number;
}

interface State {
  paste?: Paste;
  available_languages: Language[];
  current_language?: Language;
}

interface Props {}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class New_Paste extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props, state);
    this.state = {
      available_languages: [
        {
          name: "JavaScript",
          label: "JavaScript",
          id: "javascript",
          extensions: [".js", ".es6", ".jsx", ".mjs"],
        },
      ],
      paste_name: "",
      current_language: {
        name: "JavaScript",
        label: "JavaScript",
        id: "javascript",
        extensions: [".js", ".es6", ".jsx", ".mjs"],
      },
      success_snackbar_open: false,
      failure_snackbar_open: false,
      paste_error_message: "Failed to save paste.",
      code_editor_ref: createRef(),
      new_paste_dialog_ref: createRef(),
      created_paste_dialog_ref: createRef(),
    };
  }
  // Get the available languages from Monaco and parse them for formatting
  parse_available_languages(monaco_languages) {
    let languages = [];
    monaco_languages.forEach((language) => {
      languages.push({
        name: language.aliases[0],
        label: language.aliases[0],
        id: language.id,
        extensions: language.extensions,
      });
    });
    this.setState({ available_languages: languages });
  }

  // Set the editor and monaco to the state when the editor is mounted.
  editor_mounted(editor, monaco) {
    this.parse_available_languages(monaco.languages.getLanguages());
    this.setState({ editor: editor, monaco: monaco });
  }

  // Change the editor's language when the dropdown is changed
  on_language_change(event, language) {
    if (language?.id) {
      this.state.new_paste_dialog_ref.current.setState({
        language: language,
        default_language: language,
      });
      this.setState({ current_language: language });
      if (this.state?.code_editor_ref?.current) {
        this.state.code_editor_ref.current.setState({ language: language.id });
      }
    }
  }

  on_paste_name_change(event) {
    this.setState({ paste_name: event.target.value });
    this.state.new_paste_dialog_ref.current.setState({
      paste_name: event.target.value,
    });
  }

  async save_paste(paste, h_captcha_reponse) {
    // If the paste is encrypted, we need to encrypt the content first.
    if (paste.encrypted) {
      paste.content = AES.encrypt(paste.content, paste.password).toString();
    }
    try {
      var response = await axios.post(
        `${process.env.REACT_APP_API_FQDN}/new_paste`,
        {
          name: paste.name,
          author: paste.author,
          language: paste.language,
          content: paste.content,
          encrypted: paste.encrypted,
          expiration: paste.expiration,
          "h-captcha-response": h_captcha_reponse,
        }
      );
    } catch (error) {
      // Failed to save the paste.
      throw new Error(
        error.response?.data?.message || "Failed to save the paste."
      );
    }
    return response.data.uuid;
  }

  async on_save_paste() {
    this.state.new_paste_dialog_ref.current.setState({
      dialog_open: false,
    });
    let paste = {
      name: this.state.paste_name || null,
      language: this.state.current_language.id || "plaintext",
      author:
        this.state.new_paste_dialog_ref.current.state.paste_author || null,
      content: this.state.editor.getValue() || null,
      encrypted:
        this.state.new_paste_dialog_ref.current.state.encrypted || false,
      password:
        this.state.new_paste_dialog_ref.current.state.paste_password || null,
      expiration:
        this.state.new_paste_dialog_ref.current.state.expiration.value || 0,
    };
    let h_captcha_response =
      this.state.new_paste_dialog_ref.current.state.h_captcha_response || null;
    try {
      var uuid = await this.save_paste(paste, h_captcha_response);
    } catch (error) {
      this.setState({
        failure_snackbar_open: true,
        paste_error_message: error.message,
      });
      return;
    }
    this.setState({ success_snackbar_open: true }); // Show the success snackbar.
    this.state.created_paste_dialog_ref.current.setState({
      dialog_open: true,
      paste_name: this.state.paste_name,
      paste_uuid: uuid,
    }); // Show the created paste dialog. (With the URL)
    return;
  }

  on_cancel_paste() {
    this.state.new_paste_dialog_ref.current.setState({
      dialog_open: false,
    });
  }

  show_new_paste_dialog() {
    this.state.new_paste_dialog_ref.current.setState({
      dialog_open: true,
      paste_name: this.state.paste_name,
      available_languages: this.state.available_languages,
      language: this.state.current_language,
    });
  }

  render() {
    this.editor_mounted = this.editor_mounted.bind(this);
    this.on_language_change = this.on_language_change.bind(this);
    this.show_new_paste_dialog = this.show_new_paste_dialog.bind(this);
    this.on_save_paste = this.on_save_paste.bind(this);
    this.on_cancel_paste = this.on_cancel_paste.bind(this);
    this.on_paste_name_change = this.on_paste_name_change.bind(this);
    return (
      <Box sx={{ display: "flex" }} style={{ height: "89vh", width: "95vw" }}>
        <New_Paste_Dialog
          ref={this.state.new_paste_dialog_ref}
          on_save={this.on_save_paste}
          on_cancel={this.on_cancel_paste}
          on_language_change={this.on_language_change}
          on_paste_name_change={this.on_paste_name_change}
        />
        <Created_Paste_Dialog ref={this.state.created_paste_dialog_ref} />
        <Snackbar
          open={this.state.success_snackbar_open}
          autoHideDuration={2500}
          onClose={() => this.setState({ success_snackbar_open: false })}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Paste created successfully.
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.failure_snackbar_open}
          autoHideDuration={2500}
          onClose={() => this.setState({ failure_snackbar_open: false })}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {this.state.paste_error_message}
          </Alert>
        </Snackbar>
        <Grid container spacing={1}>
          <Box
            sx={{
              mx: "auto",
            }}
            style={{ width: "95vw" }}
          >
            <Grid container spacing={1}>
              <Grid item lg={3} md={2}>
                <TextField
                  size="small"
                  id="paste-name"
                  label="Paste Name"
                  variant="outlined"
                  value={this.state.paste_name}
                  sx={{
                    mx: "auto",
                  }}
                  onChange={this.on_paste_name_change}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </Grid>
              <Grid item lg={7} md={8}>
                <Autocomplete
                  disablePortal
                  id="paste-language"
                  options={this.state.available_languages}
                  sx={{ width: 250, mx: "auto" }}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Language" />
                  )}
                  onChange={this.on_language_change}
                  value={this.state.current_language}
                />
              </Grid>
              <Grid item lg={2} md={1}>
                <Button
                  variant="contained"
                  onClick={this.show_new_paste_dialog}
                  sx={{
                    width: 150,
                    mx: 5,
                  }}
                  style={{ float: "right" }}
                >
                  Save Paste
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12}>
            <CodeEditor
              editor_mounted={this.editor_mounted}
              ref={this.state.code_editor_ref}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default New_Paste;
