import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: { default: "#1e1e1e", paper: "#1e1e1e" },
  },
});

export default theme;
