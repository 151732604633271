import React, { createRef } from "react";
import Navigation from "./components/navigation";
import { Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Paste from "./pages/paste";
import New_Paste from "./pages/new-paste";
import NotFound from "./pages/404";

const links = [
  {
    name: "Home",
    path: "/",
    icon: <HomeIcon />,
  },
];

document.body.style.overflow = "hidden";

class App extends React.Component<any> {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <BrowserRouter>
        <Navigation links={links} style={{ overflow: "hidden" }}>
          <Routes>
            <Route path="/" element={<New_Paste />} />
            <Route
              path="/404_paste"
              element={<NotFound message="Paste not found or expired." />}
            />
            <Route path="/:uuid" element={<Paste />} />
            <Route path="*" element={<NotFound message="Page not found." />} />
          </Routes>
        </Navigation>
      </BrowserRouter>
    );
  }
}

export default App;
