import React, { createRef } from "react";
import Editor from "@monaco-editor/react";

class CodeEditor extends React.Component<any, any> {
  constructor(props, state) {
    super(props, state);
    this.state = {
      language: this.props.language || "javascript",
      value: this.props.content || "// BoxBin",
      options: {
        selectOnLineNumbers: true,
        roundedSelection: true,
        readOnly: this.props.read_only || false,
        cursorStyle: "line",
        cursorSmoothCaretAnimation: true,
        automaticLayout: true,
        cursorBlinking: "smooth",
        minimap: {
          enabled: false,
        },
      },
      on_change: this.props.on_change || null,
      editor_mounted: this.props.editor_mounted || null,
      theme: this.props.theme || "vs-dark",
    };
  }

  render() {
    const { language, value, options, on_change, editor_mounted, theme } =
      this.state;
    return (
      <Editor
        language={language}
        value={value}
        options={options}
        onChange={on_change}
        onMount={editor_mounted}
        theme={theme}
      />
    );
  }
}

export default CodeEditor;
