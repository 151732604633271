import React, { useState, createRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

class Created_Paste_Dialog extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props, state);
    this.state = {
      dialog_open: false,
      paste_name: "",
      paste_uuid: "",
    };
  }

  render() {
    return (
      <Dialog open={this.state.dialog_open}>
        <DialogTitle>Paste created.</DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText id="alert-dialog-description">
              The paste ({this.state.paste_name}) was created successfully. It
              is available at the following URL:
            </DialogContentText>
            <TextField
              disabled
              id="paste-url"
              label="URL"
              fullWidth
              defaultValue={`${process.env.REACT_APP_FRONTEND_FQDN}/${this.state.paste_uuid}`}
              style={{ marginTop: "15px", marginBottom: "15px" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ dialog_open: false })}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Created_Paste_Dialog;
