import React, { createRef, useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";

export function withRouter(Children) {
  return (props) => {
    let navigate = useNavigate();
    const match = { params: useParams() };
    return <Children {...props} match={match} navigate={navigate} />;
  };
}

class NotFound extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props, state);
    this.state = {};
  }

  render() {
    return (
      <Box
        sx={{ display: "flex" }}
        style={{
          height: "89vh",
          width: "95vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={1}>
          <Box
            sx={{
              mx: "auto",
            }}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid item xs={12}>
              <Typography variant="h1" style={{ margin: "auto" }}>
                404
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" style={{ margin: "auto" }}>
                {this.props.message || "Not found."}
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  }
}
export default withRouter(NotFound);
