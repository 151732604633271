import React, { useState, createRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

class Decrypt_Paste_Dialog extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props, state);
    this.state = {
      dialog_open: false,
      paste_password: "",
      password_props: {},
    };
  }

  render() {
    return (
      <Dialog open={this.state.dialog_open}>
        <DialogTitle>Decrypt paste.</DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText id="alert-dialog-description">
              This paste's content is encrypted, please provide a password to
              decrypt the paste.
            </DialogContentText>
            <TextField
              {...this.state.password_props}
              autoFocus
              margin="dense"
              id="paste_password"
              label="Paste Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={(event) =>
                this.setState({ paste_password: event.target.value })
              }
              style={{ marginTop: "15px", marginBottom: "15px" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.on_cancel}>Cancel</Button>
          <Button onClick={this.props.on_decrypt} variant="contained">
            Decrypt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Decrypt_Paste_Dialog;
