import React, { useState, createRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import { Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import HelpIcon from "@mui/icons-material/Help";
import { Tooltip } from "@mui/material";
import HCaptcha from "@hcaptcha/react-hcaptcha";

class New_Paste_Dialog extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props, state);
    this.state = {
      dialog_open: false,
      on_close: props.on_dialog_close,
      paste_name: "",
      paste_author: "",
      encrypted: false,
      paste_password: "",
      available_languages: [
        {
          name: "JavaScript",
          label: "JavaScript",
          id: "javascript",
          extensions: [".js", ".es6", ".jsx", ".mjs"],
        },
      ],
      language: {
        name: "JavaScript",
        label: "JavaScript",
        id: "javascript",
        extensions: [".js", ".es6", ".jsx", ".mjs"],
      },
      available_expiration_options: [
        {
          label: "Never",
          value: 0,
        },
        {
          label: "15 Minutes",
          value: 900,
        },
        {
          label: "30 Minutes",
          value: 1800,
        },
        {
          label: "1 Hour",
          value: 3600,
        },
        {
          label: "6 Hours",
          value: 21600,
        },
        {
          label: "12 Hours",
          value: 43200,
        },
        {
          label: "1 Day",
          value: 86400,
        },
        {
          label: "1 Week",
          value: 604800,
        },
        {
          label: "1 Month",
          value: 2592000,
        },
      ],
      expiration: {
        label: "Never",
        value: 0,
      },
      h_captcha_response: "",
      hcaptcha_ref: createRef(),
    };
  }

  on_hcaptcha_verified(token) {
    this.setState({ h_captcha_response: token });
  }

  render() {
    this.on_hcaptcha_verified = this.on_hcaptcha_verified.bind(this);
    return (
      <Dialog open={this.state.dialog_open}>
        <DialogTitle>Save New Paste</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              autoFocus
              margin="dense"
              id="paste_name"
              label="Paste Name"
              fullWidth
              variant="standard"
              defaultValue={this.state.paste_name}
              onChange={this.props.on_paste_name_change}
              style={{ marginBottom: "15px" }}
              inputProps={{
                maxLength: 50,
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="paste_author"
              label="Paste Author"
              fullWidth
              variant="standard"
              defaultValue={this.state.paste_author}
              onChange={(event) =>
                this.setState({ paste_author: event.target.value })
              }
              style={{ marginBottom: "15px" }}
              inputProps={{
                maxLength: 30,
              }}
            />
            <Autocomplete
              disablePortal
              id="dialog-paste-language"
              options={this.state.available_languages}
              size="small"
              value={this.state.language}
              renderInput={(params) => (
                <TextField {...params} label="Language" />
              )}
              onChange={this.props.on_language_change}
              style={{ marginBottom: "15px" }}
            />
            <Autocomplete
              disablePortal
              id="dialog-paste-expiration"
              options={this.state.available_expiration_options}
              size="small"
              value={this.state.expiration}
              renderInput={(params) => (
                <TextField {...params} label="Expiration" />
              )}
              onChange={(event, expiration) =>
                this.setState({ expiration: expiration })
              }
              style={{ marginBottom: "15px" }}
            />
            <Tooltip title="Whether to encrypt the paste content or not. Encryption is done in the browser and the password is never sent anywhere.">
              <FormControlLabel
                control={
                  <Switch
                    defaultValue={this.state.encrypted}
                    onChange={() =>
                      this.setState({ encrypted: !this.state.encrypted })
                    }
                  />
                }
                label="Encrypted"
              />
            </Tooltip>
            {this.state.encrypted ? (
              <TextField
                autoFocus
                margin="dense"
                id="paste_password"
                label="Paste Password"
                type="password"
                fullWidth
                variant="standard"
                onChange={(event) =>
                  this.setState({ paste_password: event.target.value })
                }
                style={{ marginTop: "15px", marginBottom: "15px" }}
              />
            ) : null}
            {process.env.REACT_APP_HCAPTCHA_ENABLED == "true" ? (
              <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY}
                onVerify={this.on_hcaptcha_verified}
                ref={this.state.hcaptcha_ref}
                theme="dark"
              />
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.on_cancel}>Cancel</Button>
          <Button onClick={this.props.on_save} variant="contained">
            Save Paste
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default New_Paste_Dialog;
